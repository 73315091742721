
header {
    position: sticky;
    top:0;
    /*z-index: 99999;*/
    z-index: 9998;
    box-shadow: 0 10px 10px 0px rgb(0 0 0 / 5%);
    background-color: $white;
}

.mini-cart {
    position: relative;
    a, a.visited {
        color: $main-color;
    }
    .badge-main {
        background-color: $pastel-2;
        position: absolute;
        right: -2px;
        top: -5px;
        color: $black;
        z-index: 999;
    }
}
