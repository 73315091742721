.main-categories-filter {
    .main-category-item {
        text-decoration: none !important;
        position: relative;
            transition: all 300ms ease;
        h2 {
            position: relative;
            z-index: 2;
            max-width: 140px;
            color: $light-font-color;
            font-size: 30px;
            font-weight: 700;
            transition: all 300ms ease;
        }
        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $dark-bg-color;
            opacity: .8;
        }
        &:after {
            position: absolute;
            z-index:3;
            content: "";
            bottom:0;
            left: 0;
            height: 3px;
            width: 0;
            transition: all 300ms ease;
            display: block;
            background-color: $main-color;
        }
        &:hover, &.active {
            &:after {
                width: 100%;
            }
            h2 {
                color: $main-color;
            }
        }
    }
}

.category-filter {

    .category-filter-item-lvl-1, .category-filter-item  {
        color: $font-color;
        transition: all 300ms ease;
        text-decoration: none !important;
        h3 {
            font-size: 22px;
            font-weight: 700;
            color: $font-color;
            transition: all 300ms ease;
        }
        &:hover, &.active {
            color: $main-color;
            h3 {
                color: $main-color;
            }
        }
    }
    .category-filter-item {

    }

}

.category-header {
    position: relative;
    background: {
        color: $pastel-1;
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 100%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url(/images/w.svg);
        background-size: 45%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
    h1 {
        position: relative;
        z-index: 2;
        display: inline-block;
        font-size: 48px;
        font-weight: 900;
        position: relative;
        //border-bottom: 3px dashed $pastel-2;
        &:after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: -10px;
            width: calc(100% + 10px);
            height: 3px;
            background-image: linear-gradient(to right, transparent 50%, $pastel-2 50%);
            background-size: 22px 100%;
        }

    }
}

.category-detail {
    position: relative;
    overflow-x: hidden;
    @include media-breakpoint-up(lg) {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 50%;
            height: calc(100%);
            transform: translateX(-25%);
            opacity: .6;
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            width: 50%;
            height: calc(100%);
            transform: translateX(20%);
            opacity: .6;
            background: {
                image: url("/images/bg-trending-right.png");
                repeat: repeat-y;
                position: right;
            }
        }
    }
}
@include media-breakpoint-up(md){
    .collapse.collapse-on-mobile{
        display: block;
        height: auto !important;
        visibility: visible;
    }
}
