section#home-intro {
    background-color: $pastel-1;
    position: relative;
    height: 800px;
    color: $pastel-1-accent;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 800px;
        right: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: right;
        }
        img {
            height: 800px;
           object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 80%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url(/images/w.svg);
        background-size: 100%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
    .uk-button.uk-button-default:hover {
        background-color: #87a4a8;
        color: $white;
    }
}

section#home-about {
    background-color: $pastel-2;
    position: relative;
    height: 700px;
    color: $pastel-1-accent;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
        color:$white;
        h2 {
            font-size: 48px;
            font-weight: 700;
            color: $white;
        }
        .uk-button.uk-button-default:hover {
            background-color: #5c6a75;
            color: $white;
        }

    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 700px;
        left: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: left;
        }
        img {
            height: 700px;
            object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 90%;
        bottom: 0;
        right: 0;
        color: $pastel-2-accent;
        content: "";
        background-image: url(/images/w2.svg);
        background-size: 100%;
        background-position: bottom right;
        background-repeat: no-repeat;
        opacity: .20;
    }
}

section#new-products {
    @extend .py-5;
    @extend .mb-4;
    .uk-position-small[class*=uk-position-center-left]{

        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(-120%) translateY(30px);
        font-size: 25px;

    }
    .uk-position-small[class*=uk-position-center-right] {

        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(120%) translateY(30px);
        font-size: 25px;

    }
    .new-content > div:first-of-type {

        text-align: center;
        @extend .mb-5;

    }
    h2 {

        display:inline-block;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        //border-bottom: 3px dashed $pastel-2;

        &:after {

            content: "";
            position: absolute;
            bottom: -10px;
            left: -10px;
            width: calc(100% + 10px);
            height: 3px;
            background-image: linear-gradient(to right, transparent 50%, $pastel-2 50%);
            background-size: 22px 100%;

        }

    }

    li {
        padding-top: 40px;

        .new-product-item {
            position: relative;
            display: block;
            &:before {

                content: "";
                position: absolute;
                top: -30px;
                left: calc(50% - 50px);
                width: 50%;
                padding-bottom: 24%;
                background-image: url(/images/tape.svg?052e661…);
                background-size: 100%;
                background-position: bottom center;
                z-index: 999;
                background-repeat: no-repeat;
                transform-origin: center;
                opacity: .8;

            }

        }
        &:nth-child(2n) .new-product-item:before {

            //left: 50px;
            transform: scaleX(-1);

        }
        &:nth-child(3n) .new-product-item:before {

            //left: 50px;
            transform:  rotate(20deg);

        }
        &:nth-child(4n) .new-product-item:before {

            //left: 50px;
            transform: rotate(-20deg) scaleX(-1);

        }

    }

}


a, .uk-link {color: #d48122;}
a:hover, .uk-link:hover, .uk-link-toggle:hover .uk-link, .uk-link-toggle:focus .uk-link {color: #333;text-decoration: none;}


section#home-social {
    background-color: $pastel-3;
    background-image: url(/images/plane.svg);
    background-repeat: no-repeat;
    background-size: 350px;
    background-position: 0 -55px ;
    height: 240px;
    @extend .py-4;
    @extend .d-flex;
    @extend .align-items-center;
    h3 {
        font-size: 32px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
    }
    h4 {
        margin-top: -15px;
        font-size: 32px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
    }

    .social-buttons {
        ul {
            @extend .row;
            @extend .px-0;
            list-style-type: none;
            li {
                @extend .col-5;
                @extend .py-3;
                &:nth-child(3) {
                    @extend .offset-lg-1;
                }
                a {
                    @extend .uk-button;
                    @extend .w-100;
                    background-color: $white;
                    box-shadow: 0 0 15px $pastel-3-accent;
                    border-radius: 5px;
                    font-size: 18px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    transition: all 300ms ease-in-out;
                    &:hover {
                        background-color: $button-dark;
                        color: $white;
                    }
                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

section#home-designs {
    background-color: $pastel-1;
    position: relative;
    height: 800px;
    color: $pastel-1-accent;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
        h2 {
            font-size: 48px;
            font-weight: 700;
        }
        .uk-button.uk-button-default:hover {
            background-color: #f7cc49;
            color: $black;
        }
    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 800px;
        right: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: right;
        }
        img {
            height: 800px;
            object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 80%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url(/images/w.svg);
        background-size: 100%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
}


.leone-home-main-1-right {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.leone-home-main-1-left a:not(#technische-kwaliteiten-anchor) {
    position: relative;
    border: 1px solid #ccc;
    padding: 11px 22px 11px 76px;
    display: inline-block;
    margin-top: 20px;
    font-weight: 400;
    color: $black;
}

.leone-home-main-1-left a:not(#technische-kwaliteiten-anchor)::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 6px;
    left: 22px;
    width: 32px;
    height: 32px;
    background: url('/images/tel-icon.png');
}

.leone-home-main-1-left a:not(#technische-kwaliteiten-anchor)::hover,
.leone-home-main-1-left a:not(#technische-kwaliteiten-anchor)::focus {
    text-decoration: none;
}

.video-container {position: relative;}
.video-container a.playbutton span.playbuttonspan {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 56px;
    height: 56px;
    margin-left: -28px;
    margin-top: -28px;
    display: inline-block;
    background: url('/images/playbutton.png');
    transition: transform .2s linear;
    transform: scale(1,1);
}

.video-container a.playbutton .playbuttontext {
    font-size: 14px;
    letter-spacing: 3.75px;
    color: $white;
    text-transform: uppercase;
    position: absolute;
    top: calc(50% + 40px);
    left: calc(50% - 54px);
}

.orange {color: $orange;}


.mobile-menu-toggle:hover,
.mobile-menu-toggle:focus,
.mini-cart a:hover,
.mini-cart a:focus {color: #333;}


.page-home .mobile-menu-toggle:hover,
.page-home .mobile-menu-toggle:focus,
.page-home .mini-cart a:hover,
.page-home .mini-cart a:focus {color: #fff;}


#leone-home-main-2-1 {position: relative;}
#leone-home-main-2-1 p {
    position: absolute;
    top: 50px;
    right: 0px;
    font-size: 50px;
    font-weight: 600;
}


#leone-home-main-2-1 p.kranten {
    position: absolute;
    bottom: -330px;
    left: 0px;
    top: auto;
    right: auto;
    max-width: 261px;
}

#leone-home-main-2-1 p.kranten #hnb, #leone-home-main-2-1 p.kranten #gva {margin-top: -15px;}

#leone-home-main-2-1 p.bekend-van {
    position: absolute;
    bottom: -175px;
    left: 0px;
    top: auto;
    right: auto;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 1;
}

#leone-home-main-2-1 p span.orange {font-weight: 900;}
#leone-home-main-2-1 p span.span2 {display: block;padding-left: 150px;}

#leone-home-voordelen,
#leone-home-partnership {background-color: #f5f5f5;}


#leone-home-voordelen > div.container {
    background: url('/images/voordelen-bg.png') no-repeat 50% 50%;
    background-size: contain;
}

#leone-home-voordelen h2, #leone-home-partnership h2 {
    text-align: center;
}

#leone-home-voordelen hr, #leone-home-partnership hr {
    border: 3px solid $orange;
    width: 80px;
    margin-left: auto;
    margin-right: auto;
}

#leone-home-voordelen-1, #leone-home-partnership-1 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}


#leone-home-voordelen-2, #leone-home-partnership-2 {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
    margin-top: 30px;
    margin-bottom: 45px;
}



#leone-home-voordelen-2,
#leone-home-voordelen-3,
#leone-home-voordelen-4,
#leone-home-partnership-2,
#leone-home-partnership-3,
#leone-home-partnership-4,
#leone-home-partnership-5 {background-color: $white;}

#leone-home-voordelen-2 > div:nth-child(1),
#leone-home-voordelen-3 > div:nth-child(2),
#leone-home-voordelen-4 > div:nth-child(1),
#leone-home-partnership-2 > div:nth-child(2),
#leone-home-partnership-3 > div:nth-child(1),
#leone-home-partnership-4 > div:nth-child(2),
#leone-home-partnership-5 > div:nth-child(1) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

#leone-home-voordelen-2 > div:nth-child(1), #leone-home-voordelen-3 > div:nth-child(2), #leone-home-voordelen-4 > div:nth-child(1), #leone-home-partnership-2 > div:nth-child(2), #leone-home-partnership-3 > div:nth-child(1), #leone-home-partnership-4 > div:nth-child(2), #leone-home-partnership-5 > div:nth-child(1) {
    padding-top: 15px;
    padding-bottom: 15px;
}


#leone-home-voordelen-2 > div:nth-child(1) > div *,
#leone-home-voordelen-3 > div:nth-child(2) > div *,
#leone-home-voordelen-4 > div:nth-child(1) > div * {max-width: 575px;margin-left: auto;margin-right: auto;}
#leone-home-partnership-2 > div:nth-child(2) > div *,
#leone-home-partnership-3 > div:nth-child(1) > div *,
#leone-home-partnership-4 > div:nth-child(2) > div *,
#leone-home-partnership-5 > div:nth-child(1) > div * {max-width: 615px;margin-left: auto;margin-right: auto;}

#leone-home-partnership-2 > div:nth-child(2) > div a strong.orange {
    text-decoration: underline;
}
#leone-home-partnership-2 > div:nth-child(2) > div a:hover strong.orange {
    color: #333;
    text-decoration: none;
}

#leone-home-partnership-2 .last-p img {
    margin-right: 25px !important;
    float: left;
}

#leone-home-partnership-2 .last-p a {
    float: right;
    color: $black;
}

#leone-home-partnership-2 .last-p img:nth-child(2) {
    max-width: 60px;
    object-fit: contain;
}

#leone-home-partnership-2 .last-p img:nth-child(3), #leone-home-partnership-2 .last-p a {
    position: relative;top: 8px;
}

/*#leone-home-partnership-2 .last-p img:nth-child(3) {
    left: 15px;
}*/


#leone-home-voordelen-3, #leone-home-voordelen-4, #leone-home-partnership-3, #leone-home-partnership-4, #leone-home-partnership-5 {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
    margin-top: 45px;
    margin-bottom: 45px;
}

#leone-home-voordelen-4 {margin-bottom: 0px;}


#leone-home-voordelen-3 > div:nth-child(1), #leone-home-partnership-2 > div:nth-child(1), #leone-home-partnership-4 > div:nth-child(1) {padding-left: 0px;}
#leone-home-voordelen-4 > div:nth-child(2), #leone-home-partnership-3 > div:nth-child(2), #leone-home-partnership-5 > div:nth-child(2) {padding-right: 0px;}

#leone-home-voordelen-3 a {
    width: 200px;
    height: 56px;
    line-height: 56px;
    background-color: #d48122;
    color: #f7f7fc;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    margin-top: 20px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
}

#leone-home-voordelen-3 a:hover,
#leone-home-voordelen-3 a:focus {
    background-color: $black;
    text-decoration: none;
}


#leone-home-voordelen-4 a {
    position: relative;
    border: 1px solid #ccc;
    padding: 11px 22px 11px 76px;
    display: inline-block;
    margin-top: 20px;
    font-weight: 400;
    color: $black;
}

#leone-home-voordelen-4 a::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 6px;
    left: 22px;
    width: 32px;
    height: 32px;
    background: url('/images/tel-icon.png');
}

#leone-home-voordelen-4 a:hover,
#leone-home-voordelen-4 a:focus {
    text-decoration: none;
}


.site-footer a {
    color: $black;
}

.site-footer a:hover,
.site-footer a:focus {color: $orange;text-decoration: none;}


#home-header {
    background: url('/images/Leone_ArmyGreen_Detail_1a.jpg') no-repeat 0 50%;
    background-size: cover;
}

.spotlight-home-top .new, .spotlight-home-top-ebike-actie span {
    /*width: 74px;*/
    width: 200px;
    height: 27px;
    background-color: #d48122;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.75px;
    color: $white;
    display: inline-block;
    line-height: 27px;
    text-align: center;
}
.spotlight-home-top .product-name, .spotlight-home-top-ebike-intro strong  {
    color: $white;
    text-transform: none;
    margin-top: 0px !important;
}

.spotlight-home-top-ebike-intro strong {
    font-weight: 800;
}

.spotlight-home-top .discount-price {
    /*font-size: 40px;*/
    font-size: 60px;
    color: $orange;
    letter-spacing: 0.75px;
    font-weight: 700;
}
.spotlight-home-top .price {
    font-size: 40px;
    color: rgba(255,255,255,0.87);
    letter-spacing: 0.75px;
    text-decoration: line-through;
   /* font-weight: 700;*/
    font-weight: 300;
    font-style: italic;
    margin-left: 20px;
    display: inline-block;
}

.spotlight-home-top-ebike-usps {
    list-style: none;
    padding-left: 0px;
}

.spotlight-home-top .savings, .spotlight-home-top .free-delivery, .spotlight-home-bottom .savings, .spotlight-home-bottom .free-delivery,
.spotlight-home-top .guarantee, .spotlight-home-top .hbvl, .spotlight-home-bottom .guarantee, .spotlight-home-bottom .hbvl,
.spotlight-home-top .test, .spotlight-home-top .configure, .spotlight-home-bottom .test, .spotlight-home-bottom .configure,
.spotlight-home-top-ebike-usps li {
    padding-left: 54px;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.75px;
    line-height: 28px;
    color: $white;
}

.spotlight-home-top-ebike-usps li + li {
    margin-top: 10px;
}

.spotlight-home-top .savings::before, .spotlight-home-top .free-delivery::before,
.spotlight-home-bottom .savings::before, .spotlight-home-bottom .free-delivery::before,
.spotlight-home-top .guarantee::before, .spotlight-home-top .hbvl::before,
.spotlight-home-bottom .guarantee::before, .spotlight-home-bottom .hbvl::before,
.spotlight-home-top .test::before, .spotlight-home-top .configure::before,
.spotlight-home-bottom .test::before, .spotlight-home-bottom .configure::before,
.spotlight-home-top-ebike-usps li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url('/images/listitem-marker.png');
    width: 32px;
    height: 32px;
}

.spotlight-home-top > div > div:nth-child(2) img {
    border-left: 6px solid #D48122;
}

.spotlight-home-bottom > div > div:nth-child(1) img {
    border-right: 6px solid #D48122;
}

.spotlight-home-top .buy-now, .spotlight-home-bottom .buy-now {
    display: inline-block;
    width: 200px;
    height: 56px;
    background-color: #d48122;
    color: #f7f7fc;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.75px;
    line-height: 56px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    margin-top: 30px;
}

.spotlight-home-top .buy-now:hover,
.spotlight-home-top .buy-now:focus,
.spotlight-home-bottom .buy-now:hover,
.spotlight-home-bottom .buy-now:focus {
    text-decoration: none;
    background-color: $white;
    color: $black;
}


.spotlight-home-top .variations, .spotlight-home-center .variations, .spotlight-home-bottom .variations {
    display: inline-block;
    width: auto;
    height: auto;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 0px;
    margin-right: 20px;
}

.spotlight-home-top .variations, .spotlight-home-bottom .variations {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    max-width: 220px;
}

.spotlight-home-top .option-col,
.spotlight-home-bottom .option-col {
    margin-right: 20px;
}


.spotlight-home-top .thumbs, .spotlight-home-bottom .thumbs {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    margin-top: 40px;
    margin-left: -20px;
}

.spotlight-home-top .thumbs .home-top-productimage, .spotlight-home-bottom .thumbs .home-bottom-productimage {
    width: calc(100% * 1 / 3.001);
    box-sizing: border-box;
    max-width: 100%;
    padding-left: 20px;
    box-sizing: border-box;
    border: none !important;
}


.page-home header {box-shadow: none;background-color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
}

body, .site-footer {
    border-top: 10px solid $orange;
}



#home-header {
   /* border-top: 10px solid $orange;*/
    /*padding-top: 190px;*/
    padding-top: 80px;
    padding-bottom: 100px;
}


#home-center {
    background-color: #020202;
}

#home-center .product-name, #home-bottom .product-name {
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: $white;
}

#home-center .discount-price, #home-bottom .discount-price {
    font-size: 30px;
    color: #d48122;
    letter-spacing: 0.75px;
    color: $orange;
}

#home-center .price, #home-bottom .price {
    font-size: 25px;
    /*color: #fff;*/
    color: rgba(255, 255, 255, 0.5);
    letter-spacing: 0.75px;
    text-decoration: line-through;
    font-weight: 300;
    font-style: italic;
    margin-left: 20px;
    display: inline-block;
    color: $white;
}

#home-center .buy-now, #home-bottom .buy-now {
    display: inline-block;
    width: 200px;
    height: 56px;
    background-color: #d48122;
    color: #f7f7fc;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.75px;
    line-height: 56px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    margin-top: 0px;
}

#home-center .buy-now:hover,
#home-center .buy-now:focus,
#home-bottom .buy-now:hover,
#home-bottom .buy-now:focus {
    text-decoration: none;
    background-color: $white;
    color: $black;
}

.spotlight-home-center .col-md-5 img {height: 100%;object-fit:cover;}
.spotlight-home-center .col-md-7 {padding-top: 50px;padding-bottom: 40px;}

#home-bottom {
    /*background: url('/images/home-bottom-bg-102b.jpg') no-repeat 0 50%;*/
    background: url('/images/home-bottom-bg2.jpg') no-repeat 0 50%;
    background-size: cover;
}


.technische-kwaliteiten-outer {overflow-x: auto;}
.technische-kwaliteiten {border-collapse: collapse;}

.technische-kwaliteiten tr:nth-child(odd) {
    background-color: #f5f5f5;
}

.technische-kwaliteiten tr td {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 33px;
    padding-right: 33px;
    min-width: 210px;
}

.technische-kwaliteiten tr td:nth-child(1) {
    text-align: right;

}





#home-must-haves {
    background: url('/images/home-must-haves-bg.png');
    background-size: cover;
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;
    position: relative;
}

#home-must-haves > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
}

#home-must-haves h2 {
    color: #D48122;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.review-section > div {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
}

.review-section-row1 {
    order: 2;-webkit-order: 2;
}

.review-section-row2 {
    order: 1;-webkit-order: 1;
}

.review-section-row2 h2 {
    color: #D48122;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 40px;
    padding-top: 20px;
}



#home-must-haves img {border-radius: 50%;}

#home-gallery {
    padding-top: 70px;
    padding-bottom: 70px;
}

#home-gallery > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
}

.home-gallery-slider {text-align: center;}

.header-introtext, .spotlight-home-top-ebike-intro {
    font-family: Poppins;
    font-size: 40px;
    line-height: 1.2;
    font-weight: 300;
    color: #FFF;
    display: block;
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
}

.spotlight-home-top-ebike-intro {
    text-transform: none;
}

#home-must-haves-slider {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    width: 100%;
}

#home-must-haves-slider ul {margin-left: -30px;}
#home-must-haves-slider li {padding-left: 30px;}
#home-must-haves-slider li a:not(#must-haves-anchor) {border-radius: 50%;border: 1px solid #D48122;padding: 10px;box-sizing: border-box;display: inline-block;}
#home-must-haves-slider li a:not(#must-haves-anchor) {position: relative;}
#home-must-haves-slider li a:not(#must-haves-anchor)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    color: #FFF;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

#home-must-haves-slider li a:not(#must-haves-anchor):hover::before {
    content: '';
    background: rgba(212,129,34,0.9);
}

/*#home-must-haves-slider li:nth-child(1) {
    display: none !important;
}*/

#home-must-haves-slider li:nth-child(1) a:not(#must-haves-anchor):hover::before {
    content: 'Bestel jouw extra batterij';
    padding: 25px;
}

#home-must-haves-slider li:nth-child(2) a:not(#must-haves-anchor):hover::before {
    content: 'Bestel jouw snellader';
    padding: 25px;
}
#home-must-haves-slider li:nth-child(3) a:not(#must-haves-anchor):hover::before {
    content: 'Bestel jouw topcase';
    padding: 25px;
}

#home-must-haves-slider li:nth-child(4) a:not(#must-haves-anchor):hover::before {
    content: 'Bestel jouw windscherm';
    padding: 25px;
}





.home-center-intro {
    font-size: 40px;
    line-height: 40px;
    font-weight: 300;
    color: #FFF;
    text-transform: uppercase;
}

.home-center-intro + p {margin-top: 0px;}

.spotlight-home-center .variations {display: none;}

.price-input:before {color: #000;}

.ui-slider.ui-slider-horizontal .ui-widget-header {
    background: #d48122;
}

.ui-slider.ui-slider-horizontal .ui-slider-handle {
    border-radius: 50%;
    width: 14px;
    height: 14px;
    background: #d48122;
    border-color: #d48122;
}

.product-list-item .product-info:before {
    background-color: #f5f5f5 !important;
}

.ui-slider.ui-slider-horizontal .ui-slider-range, .ui-slider.ui-slider-horizontal.ui-widget-content {
    border: 1px solid #d48122;
}


input:not([type=checkbox]):not([type=radio]) {
    border: 1px solid #d48122;
}

.product-detail-price {
    color: #d48122;
}
/*
.order-btn, .proceed-button a {
    background-color: #d84122 !important;
}*/
/*
.order-btn:hover, .proceed-button a:hover {
    background-color: #000 !important;
}*/

.product-list-item .open-icon img {display: none;}


.spotlight-home-top .qty-field {display: none !important;}


.spotlight-home-top form, .spotlight-home-bottom form {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
 .option-col {display: inline-block;}

.spotlight-home-top form input:nth-child(1), .spotlight-home-bottom form input:nth-child(1) {order: 1;-webit-order: 1;}
.spotlight-home-top form div:nth-child(2), .spotlight-home-bottom form div:nth-child(2) {order: 2;-webit-order: 2;}
.spotlight-home-top form div:nth-child(3), .spotlight-home-bottom form div:nth-child(3) {order: 4;-webit-order: 4;}
.spotlight-home-top form div:nth-child(4), .spotlight-home-bottom form div:nth-child(4) {order: 3;-webit-order: 3;}
.spotlight-home-top form br, .spotlight-home-bottom form br {order: 5;-webit-order: 5;}
.spotlight-home-top form br + a, .spotlight-home-bottom form br + a {order: 6;-webit-order: 6;}
.spotlight-home-top form br + a + div, .spotlight-home-bottom form br + a + div {order: 7;-webit-order: 7;}
.spotlight-home-top .inv-feedback, .spotlight-home-bottom .inv-feedback {padding: 10px;}

#product-detail .product-detail-select-options {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
}
#product-detail .product-detail-select-options div:nth-child(1) {order:1;-webkit-order:1;}
#product-detail .product-detail-select-options div:nth-child(2) {order:3;-webkit-order:3;}
#product-detail .product-detail-select-options div:nth-child(3) {order:2;-webkit-order:2;}


.spotlight-home-bottom .qty-field {display: none !important;}

.spotlight-home-bottom form .option-col {display: inline-block;}



#vesparoute-1 {padding-top: 70px;padding-bottom: 70px;}
#vesparoute-1-2 {padding-top: 70px;}
#vesparoute-1-2 > div {width: 50%;}
/*#vesparoute-1-2 > div > div {width: 50%;}*/
#vesparoute-1-2 > div:nth-child(1) > div > p {text-align: center;}
#vesparoute-1-2 > div:nth-child(1) > div > p a {
    display: inline-block;
    width: auto;
    height: 56px;
    background-color: #d48122;
    color: #f7f7fc;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.75px;
    line-height: 56px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    margin-top: 30px;
    padding: 0px 20px;
    box-sizing: border-box;
}
#vesparoute-1-2 > div:nth-child(2) > div > p {text-align: center}
#vesparoute-1-2 > div:nth-child(2) > div > p a {
    display: inline-block;
    width: auto;
    height: 56px;
    background-color: #d48122;
    color: #f7f7fc;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.75px;
    line-height: 56px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    margin-top: 30px;
    padding: 0px 20px;
    box-sizing: border-box;
}

.product-detail-select-options {margin-bottom: 30px;}
.product-detail-select-options > div {width: 100%;margin-top: 5px;margin-bottom: 5px;}
.product-detail-select-options select {
    width: 100%;max-width: 200px;
}


.product-detail-bottom-cta {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    text-align: center;
}

.product-detail-bottom-cta p {
    font-size: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.product-detail-bottom-cta a {
    display: block;
    margin-top: 40px;
    font-size: 1rem;
    font-weight: 400;
}

.product-detail-bottom-cta a span {
    display: inline-block;
    background-color: #d48122;
    color: #fff;
    padding: 10px 40px;
    border-radius: 5px;
    display: inline-block;
    font-size: 18px;
    text-decoration: none !important;
    transition: all 300ms ease-in-out;
}

.product-detail-bottom-cta a:hover span {
    background-color: #000;
    color: #fff;
}

.alert-success {
    color: #333;
    background-color: #d48122;
    border-color: #d48122;
}

.btn-outline-success {
    color: #fff;
    border-color: #fff;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #333;
    border-color: #333;
}

.qty-selector .qty-select-open {
    background-color: #d48122;
    border: 1px solid #d48122;
}

.form-steps li.enabled, .form-steps li.current {
    background-color: #d48122 !important;
    color: #FFF !important;
}

.form-steps li.enabled a {
    color: #FFF !important;
}

.form-steps li a, .form-steps li span {
    color: #333 !important;
}

.form-steps li {
    border: 1px solid #d48122 !important;
}

.form-steps a:hover {
    text-decoration: none !important;
}



#contact-header {padding-top: 45px;}
.contact-form, .contact-form-bottom {

}

.testrit-maken-main h1 {margin-bottom: 40px;}
.testrit-maken-form {margin-top: 40px;}
.testrit-maken-form h2 {margin-bottom: 40px;}
.testrit-maken-main > div > div > div > div {
    position: relative;
}

.testrit-maken-main > div > div > div > div p.vesparoute-img {
    position: absolute;
    top: 0;right:0;
}

.testrit-maken-form .row > div p {
    padding-right: 30px;
}

.testrit-maken-main > .container > .row:nth-child(1) {display: none;}

.testrit-maken-main h1 {position: relative;padding-right: 78px;}
.testrit-maken-main h1::after {
    content: '';
    background-image: url('/images/vesparoute-v1.jpg');
    width: 68px;
    height: 40px;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;right:0;
}

.contact-form textarea, .testrit-maken-form textarea  {
    border: 1px solid #d48122 !important;
}

.contact-form button, .testrit-maken-form button {
    border: 1px solid #d48122;
    background-color: #d48122;
    color: #FFF;
    padding: 10px 20px;
    text-transform: uppercase;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
}

.contact-form button:hover,
.contact-form button:focus,
.testrit-maken-form button:hover,
.testrit-maken-form button:focus {
    border: 1px solid #333;
    background-color: #333;
}

.contactmap iframe {width: 100%;min-height: 350px;}

.store-location {margin-top: 30px;width: 100%;min-height: 100px;}

.opening-hours {margin-top: 40px;}

.opening-hours table tr td:nth-child(1) {min-width: 120px;}

.store-location a.tel {
    background-color: #333;
    color: #FFF;
    padding: 10px 20px;
    text-decoration:none;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    min-width: 234px;
    display: inline-block;
    text-align: center;
}

.store-location a.mail {
    background-color: #d48122;
    color: #FFF;
    padding: 10px 20px;
    text-decoration:none;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    min-width: 234px;
    display: inline-block;
    text-align: center;
}

.store-location a.tel:hover, .store-location a.tel:focus {
    background-color: #d48122;
    color: #FFF;
    text-decoration:none;
}

.store-location a.mail:hover, .store-location a.mail:focus {
    background-color: #333;
    color: #FFF;
    text-decoration:none;
}





a.vesparoute-testrit-reserveren {
    background-color: #d48122;
    color: #FFF;
    padding: 10px 20px;
    text-decoration:none;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    display: inline-block;
    text-align: center;
}


a.vesparoute-testrit-reserveren:hover, a.vesparoute-testrit-reserveren:focus {
    background-color: #333;
    color: #FFF;
    text-decoration:none;
}

.store-location tr td:nth-child(2) {text-align: right;}

#leone-home-partnership-2 > div:nth-child(3) img,
#leone-home-partnership-3 > div:nth-child(1) img,
#leone-home-partnership-4 > div:nth-child(2) img,
#leone-home-partnership-5 > div:nth-child(1) img {
    transform: scale(1.25);
    -webkit-transform: scale(1.25);
    margin-right: 15px;
}


.extra-batterij-kopen {margin-top: 40px;}
.extra-batterij-kopen a {color: rgba(255,255,255,0.8);transition: all 0.2s ease;-webkit-transition: all 0.2s ease;font-style: italic;}
.extra-batterij-kopen a:hover, .extra-batterij-kopen a:focus {
    color: rgba(255,255,255,1);
    text-decoration: none !important;
}


.cashier-bargains {text-align: center;}
.cashier-bargains ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -30px;
}
.cashier-bargains ul li {
    padding-left: 30px;
}

.cashier-bargains a {
    position: relative;
    border-radius: 50%;
    border: 1px solid #D48122;
    padding: 10px;
    box-sizing: border-box;
    display: inline-block;
}

.cashier-bargains a::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    color: #FFF;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}


.cashier-bargains a:hover::before {
    content: "";
    background: rgba(212, 129, 34, 0.9);
}

.cashier-bargains li:nth-child(1) a:hover::before {
    content: "Bestel jouw topcase";
    padding: 25px;
}

.cashier-bargains li:nth-child(2) a:hover::before {
    content: "Bestel jouw windscherm";
    padding: 25px;
}

/*.cashier-bargains li:nth-child(3) {
    display: none !important;
}*/

.cashier-bargains li:nth-child(3) a:hover::before {
    content: "Bestel jouw extra batterij";
    padding: 25px;
}

.cashier-bargains li:nth-child(4) a:hover::before {
    content: "Bestel jouw snellader";
    padding: 25px;
}


.continue-shopping  {
    text-align: right;
}

.continue-shopping a {
    color: #333;text-decoration: none;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
}

.continue-shopping a:hover, .continue-shopping a:focus {
    color: #d48122;text-decoration: none;
}

.category-header {/*background-color: #fefefe !important;*/background-color: #eee !important;}
.category-header:before {
    background-image: url('/images/voordelen-bg.png') !important;
    opacity: 0.75 !important;
}

.testrit-header, .contact-header, .vesparoute-header {
    height: 300px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.testrit-header > div,
.testrit-header > div > div,
.contact-header > div,
.contact-header > div > div,
.vesparoute-header > div,
.vesparoute-header > div > div {height: 300px;}

.testrit-header img, .contact-header img, .vesparoute-header img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 300px;
    display: none;
}



/*
.testrit-header .row {
    background: url('/images/header-bg1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
*/

.testrit-header .row {
    /*background: url('/images/testrit-header-bg1.jpg');*/
    background: url('/images/testrit-header.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.contact-header .row {
    background: url('/images/header-bg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

}

.vesparoute-header .row {
    background: url('/images/header-bg3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 75%;

}

.testrit-maken-main .container {padding-top: 70px;}
.testrit-maken-form .container {padding-bottom: 70px;}


header .fa-shopping-cart {font-size: 32px !important;}

.main-menu {display: none;}




.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: -10px;
    background-color: #333;
    overflow-x: hidden;
    transition: 0.5s;
    /*padding-top: 60px;*/
    border-left: 10px solid #d48122;
}

.sidenav ul {list-style: none; /*padding-top: 60px;*/}
.sidenav ul li:nth-child(1) {padding-top: 60px;}
.sidenav ul li {margin-top: 15px;margin-bottom: 15px;display: inline-block;width:100%;}
.sidenav a {
    /*padding: 8px 32px 8px 32px;*/
    text-decoration: none;
    font-size: 25px;
    color: #fff;
    display: block;
    transition: 0.3s;
    padding: 0 20px 0 10px;
}

.sidenav a:hover {
    color: #d48122;
}

.sidenav .closebtn {
    position: absolute;
    top: -15px;
    right: 15px;
    font-size: 36px;
    margin-left: 50px;
}


#andere {display: none;}

.stock-notification * {
    font-size: 80%;
    font-style: italic;
}

.product-stock-notification {
    font-size: 80%;
    font-style: italic;
    margin-top: 40px;
    margin-left: 0px;
}

.product-shipping-notificationv  {
    font-size: 80%;
    font-style: italic;
    margin-top: 40px;
    margin-left: 0px;
}


.cart-page .stock-notification * {
color: #97c00e;
}

/*
#home-header > div > div > div > div > div > div:nth-child(1) > form > div:nth-child(3) > select > option:nth-child(2) {display: none;}
#home-header > div > div > div > div > div > div:nth-child(1) > form > div:nth-child(3) > select > option:nth-child(3) {display: none;}
#home-header > div > div > div > div > div > div:nth-child(1) > form > div:nth-child(3) > select > option:nth-child(4) {display: none;}
#home-header > div > div > div > div > div > div:nth-child(1) > form > div:nth-child(3) > select > option:nth-child(5) {display: none;}
#home-bottom > div > div > div > div > div > div:nth-child(2) > form > div:nth-child(3) > select > option:nth-child(2) {display: none;}
#home-bottom > div > div > div > div > div > div:nth-child(2) > form > div:nth-child(3) > select > option:nth-child(3) {display: none;}
#home-bottom > div > div > div > div > div > div:nth-child(2) > form > div:nth-child(3) > select > option:nth-child(4) {display: none;}
#home-bottom > div > div > div > div > div > div:nth-child(2) > form > div:nth-child(3) > select > option:nth-child(5) {display: none;}

#product-detail .product-detail-select-options div:nth-child(2) > select > option:nth-child(2) {display: none;}
#product-detail .product-detail-select-options div:nth-child(2) > select > option:nth-child(3) {display: none;}
#product-detail .product-detail-select-options div:nth-child(2) > select > option:nth-child(4) {display: none;}
#product-detail .product-detail-select-options div:nth-child(2) > select > option:nth-child(5) {display: none;}
*/


.notice {max-width: 630px;font-size: 12px;text-align: right;font-style: italic;}

.lg-outer {
    z-index: 9999 !important;
    background-color: #000;
}

.uk-lightbox.uk-open {
    z-index: 9999 !important;
    background-color: #000;
}

.lg-outer .lg-toolbar {
    background-color: transparent;
}

.cart-page .cashier-bargains a {border: none !important;}
.cart-page .cashier-bargains a::before {display: none;}
.cart-page .cashier-bargains a img {width: 100%;height: 100%;max-height: 207px;max-width: 207px;object-fit: contain;
    border: 1px solid #D48122;border-radius: 50%;
}


#disclaimer-main > div.container {padding-top: 70px;padding-bottom: 70px;}
#privacy-main > div.container {padding-top: 70px;padding-bottom: 70px;}
#privacy-main em {color: #333;}
#voorwaarden-main > div.container {padding-top: 70px;padding-bottom: 70px;}

.actie-geldig {color: #fff;font-size:12px;font-style: italic;margin-top: 40px;}


.contact-form-bottom-velektro h2 {position: relative;padding-right: 101px;}
.contact-form-bottom-velektro h2 img {
    position: absolute;
    top: 0;
    right: 0;
    width: 91px;
    height: 23px;
}

.site-footer a.fb {color: #d48122;}
.site-footer a.fb:hover, .site-footer a.fb:focus {color: #333;}

#related-products .container .row .new-content > div:nth-child(2) {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
}


.checkout-form .btn {
    background-color: #d48122;
    color: #fff;
    padding: 10px 40px;
    border-radius: 5px;
    display: inline-block;
    font-size: 18px;
    text-decoration: none !important;
    transition: all 300ms ease-in-out;
}

.checkout-form .btn:hover, .checkout-form .btn:focus {
    background-color: #000;
    color: #fff;
}

.checkout-form .butn-light {
    background-color: rgba(212, 129, 34, 0.75);
    color: #fff;
    padding: 10px 40px;
    border-radius: 5px;
    display: inline-block;
    font-size: 18px;
    text-decoration: none !important;
    transition: all 300ms ease-in-out;
}

.checkout-form .butn-light:hover, .checkout-form .butn-light:focus {
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
}



.ekko-lightbox {
    z-index: 9999;
    background-color: #000;
}

.ekko-lightbox .modal-header {
    display: -webkit-flex !important;
    display: flex !important;
}
.ekko-lightbox-nav-overlay a {color: #d48122 !important;opacity:1 !important;}
.ekko-lightbox-nav-overlay a span {color: #d48122;}
.ekko-lightbox .close {color: #d48122 !important;opacity:1 !important;}


.uk-modal.uk-open {
    opacity: 1;
    background: #000;
    margin: 0px !important;
}


.product-main-left > div:nth-child(1) {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.fruitbomen, .testrit-maken-main2-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.fruitbomen img {max-width: 350px;max-height: 275px !important;object-fit: contain;}


.testrit-maken-main2 {
    background-color: #f5f5f5;
    padding-top: 40px;
    padding-bottom: 70px;
}

.testrit-maken-main2 > div > div {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.testrit-maken-main2 > div > div::after {
    content: "";
    background-image: url('/images/Bromfun-logo.png');
/*    width: 100px;
    height: 100px;*/
    width: 68px;
    height: 68px;
    background-repeat: no-repeat;
    position: absolute;
    top: -50px;
    right: 15px;
    background-size: contain;
}

.testrit-maken-main3 {
    background-color: #f5f5f5;
    padding-top: 0px;
    padding-bottom: 70px;
}

.site-footer .payment-options {
    padding-left: 0px;
    margin-top: 40px;
}

.site-footer .payment-options li {
    display: inline-block;
}

.site-footer .payment-options li + li {
    margin-left: 15px;
}

.site-footer .payment-options li img {
    width: 50px;
}

.pay-safe {
    border: 1px solid rgb(165, 228, 186);
    background-color: rgb(235, 249, 240);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding: 20px 10px;
    margin-bottom: 30px;
}

.pay-safe .col-1 {
    text-align: center;
    padding-right: 5px;
}

.pay-safe .col-11 {
    padding-left: 5px;
}

.pay-safe img {
    width: 47px;
    height: 52px;
}

.pay-safe p {
    margin-bottom: 0px;
}

/*
.product-stock-notification .product-shipping-notification {
    width: 100%;
}

.product-stock-notification .product-shipping-notification + div {
    display: none;
}
*/

.cart-page .product-shipping-notificationv + .stock-notification {
    display: none;
}




.hubodiv {
    background: rgba(0,0,0,0.7);
    padding: 20px;
    border: 1px solid #d38123;
}
.hubo {margin-bottom: 0px;margin-top: 10px;}
.hubo .home-top-hubo {
    color: #FFF;
    font-size: 20px;
    text-decoration: underline;
}
.hubo .home-top-hubo:hover,
.hubo .home-top-hubo:focus {
    color: #d48122;
}

.hubodiv .new {
    font-family: "Poppins", sans-serif;
    width: 200px;
    height: 27px;
    background-color: #d48122;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.75px;
    color: #fff;
    display: inline-block;
    line-height: 27px;
    text-align: center;
}


@media (min-width: 768px) {
    .pay-safe {
        margin-left: 0px;
    }
}

@media (max-width: 767px) {

    .pay-safe {
        margin-left: 0px;
        margin-right: 0px;
    }

    .testrit-maken-main2 > div > div {/*padding-right: 100px;*/padding-top: 40px;}
}

.testrit-maken-main2-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.testrit-maken-main2-col2 img {max-width: 275px;
    max-height: 275px !important;
    object-fit: contain;
}




.related-product-price-normal {
    text-decoration: line-through;
    color: #aaa;
    font-size: 16px;
}

.related-product-price-promo {
    color: #d48122;
    line-height: 33.75px;
    font-size: 24px;
    font-weight: 700;
}


.algemene-info-main {
    padding-left: 15px;
    padding-right: 15px;
}

.algemene-info-main ol {
    counter-reset: item;
}

.algemene-info-main ol li {
    display: block;
    position: relative;
    padding-left: 45px;
}

.algemene-info-main ol li:before {
    content: counters(item, ".") " ";
    /*content: counters(item, ".") ".";*/
    counter-increment: item;
    /*padding-right:10px;
    margin-left:-20px;*/
    position: absolute;
    top: 0;
    left: 0;
}

.algemene-info-main h1 {margin-bottom: 40px;}
.algemene-info-main ol {font-weight: bold;}
.algemene-info-main ol ol {margin-top: 20px;margin-bottom: 40px;margin-left: -45px;padding-left: 0px;font-weight: normal;}






.review-section {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    text-align: center;
}

.review-section > div {padding-top: 35px;padding-bottom: 35px;}

.blockquote-wrapper {
    position: relative;
    font-family: "Poppins", serif;
    max-width: 420px;
    margin: 35px 20px;
    align-self: center;
    display: inline-block;

}


.blockquote-wrapper p {
    font-family: "Poppins", serif;
    position: relative;
    color: #000;
    font-size: 16px;
    font-weight: bolder;
    line-height: 1;
    margin: 0;
    border: 2px solid #d48122;
    border-radius:20px;
    padding: 50px 10px;
    /*letter-spacing: 0.75px;*/
    letter-spacing: 0.6px;
    line-height: 28px;
    font-style: italic;
    text-align:left;
    min-height: 156px;
}

.blockquote-wrapper p:after {
    content:"";
    position: absolute;
    border: 2px solid #d48122;
    border-radius: 0 50px 0 0;
    width: 60px;
    height: 60px;
    bottom: -60px;
    left: 50px;
    border-bottom: none;
    border-left: none;
    z-index: 3;
}

.blockquote-wrapper p:before {
    content:"";
    position: absolute;
    width: 80px;
    border: 6px solid #fff;
    bottom: -3px;
    left: 50px;
    z-index: 2;
}



.blockquote-wrapper h4 {
    position: relative;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    padding-top: 15px;
    z-index: 1;
    /*margin-left:150px;*/
    margin-left:133px;
    padding-left:12px;
    letter-spacing: 0.75px;
    text-align: left;
}


.blockquote-wrapper h4:first-letter {
    margin-left:-12px;
}




/*.spotlight-home-top .new,
#leone-home-partnership-5 {
    display: none;
}*/
.spotlight-home-top .new, .spotlight-home-top-ebike-actie span {
    display: none;
}

.spotlight-home-top .new, .spotlight-home-top-ebike-actie span {
    display: inline-block;
}


@media (max-width:959px) {
    .blockquote-wrapper {margin: 20px auto;}
}



@media (min-width: 1320px) {
    /*.blockquote-wrapper-1 {position: relative;left: 45px;}
    .blockquote-wrapper-2 {position: relative;left: 155px;}
    .blockquote-wrapper-3 {position: relative;left: 240px;}
    .blockquote-wrapper-4 {position: relative;left: 355px;}*/
    .blockquote-wrapper-1 {position: relative;left: -145px;}
    .blockquote-wrapper-2 {position: relative;left: -35px;}
    .blockquote-wrapper-3 {position: relative;left: 50px;}
    .blockquote-wrapper-4 {position: relative;left: 165px;}
}

@media (max-width: 1319px) {
    .review-section > div > div {display:block;}
}

/* media */

body:not(.page-home) header > div {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

body:not(.page-home) header > div img.header-logo {max-width: 250px;}

.home-wie-zijn-we {
    background-color: #f5f5f5;
    padding-top: 70px;
    padding-bottom: 70px;
}

.home-wie-zijn-we h2 {font-size: 30px;}
.home-wie-zijn-we p {max-width: 570px;}
.home-wie-zijn-we img {margin-top: 40px;margin-bottom:20px;}

.testrit-maken-top-block {padding-top: 70px;padding-bottom: 70px;}
.testrit-maken-top-block p {max-width: 420px;}
.testrit-maken-main, .testrit-maken-form {background-color: #f5f5f5;}
.testrit-maken-form {
    margin-top: 0px;
    padding-top: 40px;
}

$breakpoint-mobile: 768px;
@media (min-width: $breakpoint-mobile) {

    .mobile-menu-icon {
        width: 30px;
        display: inline-block;
        padding-top: 15px;
    }

    .product-main-left > div:nth-child(1) .thumbs {
        margin-top: 40px;
        display: -webkit-flex;
        display: flex;
    }

    .product-main-left > div:nth-child(1) .thumbs .home-top-productimage {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .product-main-left > div:nth-child(1) .home-top-productimage-top {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .contact-form form {max-width: 900px;}
    .contact-form label {width: 35%;}
    .contact-form input, .contact-form textarea {width: 65%;}
    .contact-form .privacy-notice-paragraph, .contact-form button {margin-left: 35%;}

    .testrit-maken-form form {max-width: 900px;}
    .testrit-maken-form label {width: 35%;}
    .testrit-maken-form input, .contact-form textarea {width: 65%;}
    .testrit-maken-form .privacy-notice-paragraph, .testrit-maken-form button {margin-left: 35%;}

}


$breakpoint-desktop: 1440px;
@media (min-width: $breakpoint-desktop) {
    #leone-home-main-1 {padding-top: 100px;padding-bottom: 50px;}
    #leone-home-main-2 {padding-top: 50px;padding-bottom: 190px;}
    #leone-home-main-2-1 #scooter1 {position: relative;z-index: 1;max-width: 37vw;}
    #leone-home-main-2-1 #scooter2 {
        position: relative;z-index:2;
        left: -225px;
        top: 290px;
    }
    #leone-home-main-2-1 #scooter3 {
        position: relative;z-index:3;
        left: -265px;
        top: 200px;
    }

    #leone-home-voordelen {
        margin-top: 135px;
        padding-top: 55px;
        padding-bottom: 45px;
    }

    #leone-home-partnership {
        padding-top: 60px;padding-bottom: 45px;
    }

    #home-bottom > div.container {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .cart-page .cashier-bargains a img {
        width: 207px;height: 207px;
    }

}



$breakpoint-desktop-large: 1680px;
@media (min-width: $breakpoint-desktop-large) {
    #leone-home-main-2-1 #scooter2 {
        position: relative;z-index:2;
        left: -225px;
        top: 290px;
    }
    #leone-home-main-2-1 #scooter3 {
        position: relative;z-index:3;
        left: -265px;
        top: 200px;
    }
}




@media (min-width: 1451px) and (max-width: 1680px) {

}

$breakpoint-desktop-large: 1439px;
@media (max-width: $breakpoint-desktop-large) {
    #leone-home-main-2-1 p {
        position: relative;
        top: 0px;
        left: 0px;
        right: auto;
    }

    #leone-home-main-2-1 #scooter1 {
        margin: 15px;
    }
    #leone-home-main-2-1 #scooter2 {
        position: relative;
        top: 0px;
        left: 0px;
        right: auto;
        margin: 15px;
    }
    #leone-home-main-2-1 #scooter3 {
        position: relative;
        top: 0px;
        left: 0px;
        right: auto;
        margin: 15px;
    }

    #leone-home-main-2-1 p.kranten {
        position: relative;
        bottom: 0px;
        left: 0px;
        top: auto;
        right: auto;
        max-width: 261px;
    }

    #leone-home-main-2-1 p.bekend-van {
        position: relative;
        bottom: 0px;
        left: 0px;
        top: auto;
        right: auto;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        z-index: 1;
    }

    .cart-page .cashier-bargains a img {
        width: 157px;height: 157px;
    }

    .cashier-bargains ul li {
        padding-left: 20px;
    }



}


$breakpoint-tablet: 1439px;
@media (max-width: $breakpoint-tablet) {
    #leone-home-main-1 {padding-top: 100px;padding-bottom: 50px;}
    /*#leone-home-main-2 {padding-top: 50px;padding-bottom: 190px;}*/
    #leone-home-main-2 {padding-top: 50px;padding-bottom: 50px;}
    #leone-home-main-2-1 #scooter1 {position: relative;z-index: 1;}
    /*#leone-home-main-2-1 #scooter2 {
        position: relative;z-index:2;
        left: -225px;
        top: 290px;
    }
    #leone-home-main-2-1 #scooter3 {
        position: relative;z-index:3;
        left: -265px;
        top: 200px;
    }*/

    #leone-home-voordelen {
        /*margin-top: 135px;*/
        margin-top: 0px;
        padding-top: 55px;
        padding-bottom: 45px;
    }

    #leone-home-partnership {
        padding-top: 60px;padding-bottom: 45px;
    }

    #home-bottom > div.container {
        padding-top: 100px;
        padding-bottom: 100px;
    }


}




$breakpoint-lm: 1199px;
@media (max-width: $breakpoint-lm) {
    #related-products .container .row .new-content > div:nth-child(2){
        display: block;
    }

}



$breakpoint-l: 959px;
@media (max-width: $breakpoint-l) {

    .category-header .col-lg-6 {text-align: center;padding-top: 40px;padding-bottom: 40px;}

    .product-detail-bottom-cta {padding-top: 40px;}

    .spotlight-home-top > div > div:nth-child(1) {padding-left: 30px;padding-right: 30px;}
    .spotlight-home-top > div > div:nth-child(2) {padding-top: 45px;}
    #leone-home-main-2-1 {text-align: center;}
    #leone-home-main-2-1 #scooter2 {top: 0;left: 0;margin-top: 40px;margin-bottom: 40px;}
    #leone-home-main-2-1 #scooter3 {top: 0;left: 0;}
    .spotlight-home-bottom > div > div:nth-child(2) {padding-left: 30px;padding-right: 30px;}

    .site-footer > div > div > div:nth-child(1) {order: 1;-webkit-order: 1;}
    .site-footer > div > div > div:nth-child(2) {order: 2;-webkit-order: 2;}
    .site-footer > div > div > div:nth-child(3) {order: 3;-webkit-order: 3;}

    .spotlight-home-top .variations, .spotlight-home-bottom .variations {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .spotlight-home-top .buy-now, .spotlight-home-bottom .buy-now {
        margin-top: 15px;
    }




}


$breakpoint-m: 767px;
@media (max-width: $breakpoint-m) {


    #related-products .container .row .new-content > div:nth-child(2){
       display: block;
    }

    #leone-home-voordelen-3 > div:nth-child(1), #leone-home-partnership-2 > div:nth-child(1), #leone-home-partnership-4 > div:nth-child(1) {
        padding-left: 0px;padding-right: 0px;
    }

    .contact-form label {width: 100%;}
    .contact-form input, .contact-form textarea {width: 100%;}

    .testrit-maken-form label {width: 100%;}
    .testrit-maken-form input, .contact-form textarea {width: 100%;}


    .spotlight-home-center .row div.col-md-5 {order:2;-webkit-order:2;padding-top: 40px;}
    .spotlight-home-center .row div.col-md-7 {order:1;-webkit-order:1;}

    #leone-home-main-2-1 p {padding-bottom: 40px;}
    #leone-home-main-2-1 p span.span2 {padding-left: 0px;}

    #leone-home-voordelen-3 > div:nth-child(1) {order: 2;-webkit-order: 2;}
    #leone-home-voordelen-3 > div:nth-child(2) {order: 1;-webkit-order: 1;}

    #leone-home-voordelen-4 > div:nth-child(2) {padding-left: 0px;}


    #leone-home-partnership-3 > div:nth-child(1) {order: 2;-webkit-order: 2;}
    #leone-home-partnership-3 > div:nth-child(2) {order: 1;-webkit-order: 1;padding-left: 0px;}

    #leone-home-partnership-2 > div:nth-child(3) img, #leone-home-partnership-3 > div:nth-child(1) img, #leone-home-partnership-4 > div:nth-child(2) img, #leone-home-partnership-5 > div:nth-child(1) img {
        transform: scale(1);
        -webkit-transform: scale(1);
    }

    #leone-home-partnership-5 > div:nth-child(1) {order: 2;-webkit-order: 2;}
    #leone-home-partnership-5 > div:nth-child(2) {order: 1;-webkit-order: 1;padding-left: 0px;}


    .spotlight-home-bottom > div > div:nth-child(2) {padding-top: 40px;}

    #leone-home-main-1 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    #leone-home-main-2 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    #leone-home-voordelen {
        margin-top: 0px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .cart-page .cashier-bargains a img {
        width: 107px;height: 107px;
    }

    .cashier-bargains ul li {
        padding-left: 10px;
    }


}



.sidenav .scootersubmenu a {
    font-size: 16px;
}

.sidenav .scootersubmenu + .scootersubmenu {
    margin-top: 5px;
}

.must-haves-section .uk-slider-container {
    overflow: visible !important;
}

.home-ebike-5-row1 {
    display: none !important;
}

@media (max-width: 991px) {

    .site-footer > div > div > div:nth-child(1) {order: 1;-webkit-order: 1;}
    .site-footer > div > div > div:nth-child(2) {order: 2;-webkit-order: 2;}
    .site-footer > div > div > div:nth-child(3) {order: 3;-webkit-order: 3;}

.site-footer .col-lg-6 {
    text-align: center !important;
}

    .category-header > div > div > div.col-lg-6 {
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .category-header > div > div > div.col-5 {
        flex: 0 0 100%;
        max-width: 100%;
        display: none;
    }

    .category-detail .container {
        max-width: none;
    }

    .category-header div.col-lg-5 {
        display: none;
    }

    .cart-page .category-header + .container.px-5.my-5, .checkoutcontainermain {
        padding-left: 20px !important;
        padding-right: 20px !important;
        max-width: none;
    }
    .pay-safe {
        margin-right: 0px;
    }

    .cart-page .cart-totals.pl-4 {
        padding-left: 0px !important;
    }

    .checkoutcarttotals .cart-totals {
        padding-left: 0px !important;
    }

    .cart-page .cart-totals.pl-4 > .row,
    .checkoutcarttotals .cart-totals > .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .cart-page .cashier-bargains a img {
        width: 120px;
        height: 120px;
    }

    .cartpagetotals {
        flex: 0 0 100%;
        max-width: 100%;
    }

}

body.cart-page, body.checkout-page {
    overflow-x: hidden;
}

@media (max-width: 767px) {

    .pay-safe .col-1 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .pay-safe .col-11 {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        margin-top: 30px;
    }

    .form-steps li {
        width: 48%;
        margin-bottom: 2%;
    }

    .cashier-bargains ul {
        margin-left: -15px;
    }
}

$breakpoint-sm: 640px;
@media (max-width: $breakpoint-sm) {

    .checkout-form .btn {
        margin-bottom: 40px;
    }

    .category-detail > div > div > div > div > div.col-6 {
            flex: 0 0 100%;
            max-width: 100%;
    }

    .category-header h1 {
        font-size: 32px !important;
    }

    .category-header > div > div > div.col-5 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .cart-page .cashier-bargains a img {
        width: 67px;
        height: 67px;
    }


        #leone-home-main-2-1 p {font-size: 30px;}

    header > div.px-5 {padding-left: 15px !important;padding-right: 15px !important;}
    header > div > div > div.col-5.d-md-none.text-right {display: none;}
    header > div > div > div.col-1.col-md-3.text-right.order-md-12 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    h2 {font-size: 32px;}
    h3 {font-size: 24px;}


    .px-5 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .header-introtext, .spotlight-home-top-ebike-intro {font-size: 20px;}
    .spotlight-home-top h1 {font-size: 30px;}
    #home-header {padding-left: 15px !important;padding-right: 15px !important;}

    #leone-home-main-2-1 #scooter1 {
        margin: 15px auto;
    }
    #leone-home-main-2-1 #scooter2 {
        margin: 15px auto;
    }
    #leone-home-main-2-1 #scooter3 {
        margin: 15px auto;
    }


    .uk-slider-container {
        overflow: hidden;
        max-width: 75vw;
    }



    .spotlight-home-top .variations, .spotlight-home-bottom .variations,
    #home-center .buy-now, #home-bottom .buy-now {width: 100%;max-width: none;}
    .spotlight-home-top form div:nth-child(2), .spotlight-home-bottom form div:nth-child(2) {}
    .spotlight-home-top form .option-col {
        display: block;width: 100%;margin-left: 0;margin-right: 0;
    }
    .spotlight-home-bottom form .option-col {
        display: block;width: 100%;margin-left: 0;margin-right: 0;
    }
    .spotlight-home-top .variations, .spotlight-home-bottom .variations {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .algemene-info-main h1 {font-size: 24px;}

    .algemene-info-main ol {padding-left: 0px;}


}

.testrit-maken-form-hide {
    display: none !important;
}

@media (max-width: 1199px) {
    .velektrohasselt {margin-top: 40px;}
}

@media (max-width: 991px) {
    .velektropelt {margin-top: 40px;}
}

@media (min-width: 768px) and (max-width: 959px) {
    .vesparoute-buttons a  {
        display: block;
    }
    .vesparoute-buttons a + a {
        margin-top: 20px;
    }
}

@media (max-width: 500px) {
    .vesparoute-buttons a  {
        display: block;
    }
    .vesparoute-buttons a + a {
        margin-top: 20px;
    }
}


$breakpoint-xsm: 470px;
@media (max-width: $breakpoint-xsm) {
    .cashier-bargains a {padding: 3px;}
    .cart-page .cashier-bargains a img {
        width: 60px;
        height: 60px;
    }

    body:not(.page-home) header > div img.header-logo {
        max-width: 170px;
    }

    #product-detail .col-lg-9 .col-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    #product-detail .col-lg-9 .col-8 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 40px;
    }

    .form-steps li {
        width: 100%;
        margin-bottom: 2%;
    }


}


.invalid-feedback {display: block;}





.img-replace {
    display: inline-block;
    overflow: hidden;
    text-indent: 100%;
    color: transparent;
    white-space: nowrap;
}
.bts-popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s;
    display: -webkit-box;
    box-sizing: border-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}
.bts-popup.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s;
}

.bts-popup-container {
    position: relative;
    width: 90%;
    max-width: 783px;
    margin: 4em auto;
    background: transparent;
    border-radius: none;
    text-align: center;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
    /* Force Hardware Acceleration in WebKit */
    -webkit-backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
    border: 1px solid #d48122 !important;
    padding: 0 !important;
}
.bts-popup-container img {
    padding: 0;
}

.bts-popup-container .bts-popup-button {
    padding: 5px 25px;
    border: 2px solid white;
    display: inline-block;
    margin-bottom: 10px;
}
.bts-popup-container .bts-popup-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
}
.bts-popup-container .bts-popup-close::before, .bts-popup-container .bts-popup-close::after {
    content: '';
    position: absolute;
    top: 12px;
    width: 16px;
    height: 3px;
    background-color: white;
}
.bts-popup-container .bts-popup-close::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 8px;
}
.bts-popup-container .bts-popup-close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 6px;
    top: 13px;
}
.is-visible .bts-popup-container {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
@media only screen and (min-width: 1170px) {
    .bts-popup-container {
        margin: 8em auto;
    }
}


#privacy-main, #disclaimer-main {
    padding-left: 15px;
    padding-right: 15px;
}


.product-video-testimonials .uk-slider-container {
    text-align: center;
}

.product-video-testimonials h2 {
    margin-top: 40px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    font-weight: 400;
    text-transform: none;
    text-align: center;
}

.product-video-testimonials .blockquote-wrapper {
    margin: 0px !important;
}

.product-video-testimonials .uk-slider-container {
    max-width: none !important;
}

.product-video-testimonials .blockquote-wrapper-1,
.product-video-testimonials .blockquote-wrapper-2,
.product-video-testimonials .blockquote-wrapper-3,
.product-video-testimonials .blockquote-wrapper-4 {
    position: relative;
    left: auto;
    right: auto;
}

.product-video-testimonials .uk-dotnav {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    margin-top: 40px;
}

@media (min-width: 768px) {
    .product-video-testimonials h2 {
        margin-top: 80px;
    }

    #product-detail .container {
        max-width: 1450px;
    }

}


.qty-selector .qty-select-options {
    border: 1px solid #d48122;
}

.qty-selector .qty-select-options div:hover {
    background-color: #d48122;
    color: #FFF;
}
.qty-selector .qty-select-options::-webkit-scrollbar-thumb {
    background: #d48122;
}






.page-home-scooter-ebike header,
.page-home-scooter-ebike footer {
    display: none;
}

.page-home-scooter-ebike .container-fluid {
    text-align: center;
}

.page-home-scooter-ebike .container-fluid > .row {
    background: url('/images/Leone-E-Bikes-37-1.png') no-repeat 50% 50%;
    background-size: cover;
    height: 558px;
}

.page-home-scooter-ebike .container-fluid > .row > div {
    background: rgba(0, 0, 0, 0.67);
}


.page-home-scooter-ebike .header-logo {
    position: relative;
    top: 76px;
    height: 77px;
}

.page-home-scooter-ebike .container {
    max-width: 988px;
    padding-bottom: 40px;
}

.page-home-scooter-ebike .container > .row {
    max-width: 988px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -341px;
    position: relative;
    background: #FFF;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.25);
}


.page-home-scooter-ebike .home-scooter-img,
.page-home-scooter-ebike .home-ebike-img {
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    min-height: 510px;
}

.page-home-scooter-ebike .home-scooter-txt,
.page-home-scooter-ebike .home-ebike-txt {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
}

.page-home-scooter-ebike .home-scooter-txt a,
.page-home-scooter-ebike .home-ebike-txt a {
    display: block;
    color: #000;
}

.page-home-scooter-ebike .home-scooter-txt a,
.page-home-scooter-ebike .home-ebike-txt a {
    position: relative;
    padding-left: 40px;
}

.page-home-scooter-ebike .home-scooter-txt a img,
.page-home-scooter-ebike .home-ebike-txt a img {
    position: absolute;
    top: 3px;
    left: 0;
}

.page-home-scooter-ebike .home-scooter-txt a:hover,
.page-home-scooter-ebike .home-ebike-txt a:hover,
.page-home-scooter-ebike .home-scooter-txt a:focus,
.page-home-scooter-ebike .home-ebike-txt a:focus {
    color: #d48122;
}

.page-home-scooter-ebike .home-scooter-txt a + a,
.page-home-scooter-ebike .home-ebike-txt a + a {
    margin-top: 10px;
}


@media (min-width: 1221px) {
    .page-home-scooter-ebike .home-scooter-txt,
    .page-home-scooter-ebike .home-ebike-txt {
        padding-left: 35px;
        padding-right: 35px;
        padding-bottom: 50px;
    }
}

@media (max-width: 1220px) {
    .page-home-scooter-ebike .home-scooter-txt,
    .page-home-scooter-ebike .home-ebike-txt {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 50px;
    }
}

@media (max-width: 959px) {
    .page-home-scooter-ebike .home-scooter-txt,
    .page-home-scooter-ebike .home-ebike-txt {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }
}








.page-home-ebike .home-ebike-1 {
    background: url('/images/ebike-header-bg.png') no-repeat 50% 50%;
    background-size: cover;
    min-height: 784px;
    position: relative;
}

.page-home-ebike .home-ebike-1::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90.19deg, rgba(0, 0, 0, 0.67) 0.21%, rgba(0, 0, 0, 0.383856) 48.48%, rgba(0, 0, 0, 0) 99.87%);
}

.page-home-ebike .home-ebike-1 > div {
    position: relative;
    z-index: 2;
}

.page-home-ebike .home-ebike-3 {
    background: #F5F5F5;
    text-align: center;
}


.spotlight-home-center-ebike-try-title h2,
.spotlight-home-center-ebike-title h2 {
    font-size: 30px;
}

.spotlight-home-center-ebike-try-intro {
    max-width: 792px;
    margin-left: auto;
    margin-right: auto;
}

.ebike-cta a,
.spotlight-home-center-ebike-try-cta a {

    display: inline-block;
    /*width: 250px;
    height: 56px;*/
    padding-left: 15px;
    padding-right: 15px;
    background-color: #d48122;
    color: #f7f7fc;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.75px;
    line-height: 56px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    margin-top: 20px;

}

.ebike-cta a {
    margin-top: 30px;
}

.ebike-cta a:hover,
.ebike-cta a:focus,
.spotlight-home-center-ebike-try-cta a:hover,
.spotlight-home-center-ebike-try-cta a:focus {

    text-decoration: none;
    background-color: #fff;
    color: #000;

}


.home-ebike-2 {
    text-align: center;
}

.ebike-name {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 220%;
    color: #000;
}

.ebike-name a {
    color: #000;
}

.ebike-name a:hover,
.ebike-name a:focus {
    colro: #D48122;
}

.ebike-price {
    margin-bottom: 20px;
}

.spotlight-home-center-ebikes {
    max-width: 1060px;
    margin-left: auto;
    margin-right: auto;
}

.spotlight-home-center-ebikes > div {
    box-shadow: 1px 355px 142px rgba(0, 0, 0, 0.01), 0px 200px 120px rgba(0, 0, 0, 0.03), 0px 89px 89px rgba(0, 0, 0, 0.04), 0px 22px 49px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
}

.ebike-price .current-price {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.75px;
    color: #D48122;
    display: inline-block;
}

.ebike-price .old-price {

    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    font-size: 25px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.75px;
    text-decoration-line: line-through;
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.5;
    display: inline-block;
}

.ebike-data:nth-child(odd) {
    background: #F5F5F5;
}

.ebike-data {
    line-height: 35px;
}

.ebike-data > div:nth-child(1), .ebike-data > section:nth-child(1) {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 220%;
    color: #D48122;
}


.ebike-hunter-txt > .row,
.ebike-rhino-txt > .row {
    margin-left: 0px;
    margin-right: 0px;
}

.spotlight-home-center-ebikes > .row {
    margin-left: 0px;
    margin-right: 0px;
}

.spotlight-home-center-ebikes > .row > div {
    padding-left: 0px;
    padding-right: 0px;
}

.home-ebike-4 {
    background: url('/images/Leone-E-Bikes-4-1.png') no-repeat 50% 50%;
    background-size: cover;
    border-top: 6px solid #d48122;
}

.home-ebike-4 > div {
    background: rgba(0, 0, 0, 0.67);
}

.page-home-ebike .site-footer {
    border-top: none;
}

.home-ebike-4 > div {
    max-width: none;
}

.home-ebike-4 > div > div {
    max-width: 1450px;
    margin-left: auto;
    margin-right: auto;
}

.page-home-ebike header {
    background: transparent;
    margin-bottom: -143px;
}

.home-ebike-1 {
    padding-top: 275px;
}

.page-home-ebike .headerbg {
    background: #FFF;
}

@media (min-width: 960px) {

    /*.spotlight-home-center-ebikes > div {
        padding: 45px 40px;
    }*/

    .ebike-hunter-img,
    .ebike-rhino-img {
        padding: 40px 45px;
    }

    .ebike-hunter-txt,
    .ebike-rhino-txt {
        padding: 40px 45px;
    }

    #product-detail .ebike-hunter-txt,
    #product-detail .ebike-rhino-txt {
        padding: 40px 0px;
    }

    .ebike-data > div:nth-child(1), .ebike-data > section:nth-child(1) {
        text-align: right;
    }

    .ebike-data > div:nth-child(2), .ebike-data > section:nth-child(2) {
        text-align: left;
    }

    .home-ebike-2 > div {
        padding-top: 75px;
        padding-bottom: 70px;
    }

    .home-ebike-3 > div,
    .home-ebike-4 > div {
        padding-top: 66px;
        padding-bottom: 66px;
    }

}


@media (min-width: 1680px) {
    .home-ebike-4 .uk-slider-items {
        margin-left: -55px;
    }
    .home-ebike-4 .uk-slider-items > li {
        padding-left: 55px;
    }

    .home-ebike-4 .uk-position-center-left-out {
        right: calc(100% + 15px)
    }

    .home-ebike-4 .uk-position-center-right-out {
        left: calc(100% + 15px)
    }
}

@media (min-width: 960px) and (max-width: 1679px) {
    .home-ebike-4 .uk-slider-items {
        margin-left: -40px;
    }
    .home-ebike-4 .uk-slider-items > li {
        padding-left: 40px;
    }

    .home-ebike-4 .uk-position-center-left-out {
        right: calc(100% - 15px)
    }

    .home-ebike-4 .uk-position-center-right-out {
        left: calc(100% - 15px)
    }
}

@media (max-width: 959px) {

    .home-ebike-4 .uk-position-center-left-out {
        right: calc(100% - 15px)
    }

    .home-ebike-4 .uk-position-center-right-out {
        left: calc(100% - 15px)
    }

    .ebike-hunter-img,
    .ebike-rhino-img {
        padding: 40px 20px;
    }

    .ebike-hunter-txt,
    .ebike-rhino-txt {
        padding: 40px 20px;
    }

    #product-detail .ebike-hunter-txt,
    #product-detail .ebike-rhino-txt {
        padding: 40px 0px;
    }

    .spotlight-home-center-ebikes > div {
        padding: 40px 20px;
    }

    .home-ebike-2 > div {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-ebike-3 > div,
    .home-ebike-4 > div {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}



@media (max-width: 640px) {
    .home-ebike-4 .uk-slider-container {
        overflow: hidden;
        max-width: 100vw;
    }
}


.store-location td {
    display: block;
    width: 100%;
}

.store-location td + td {
    padding-top: 40px;
}

.store-location a.tel,
.store-location a.mail {
    min-width: 260px;
}

.select-pickup-location .form-group label {
    position: relative;
}

.select-pickup-location .form-group label {
    padding-left: 40px;
}

.select-pickup-location .form-group label::before {
    position: absolute;
    top: 0;
    left: 0;
}

@media (min-width: 992px) {
    .store-location {
        min-height: 363px;
        margin-bottom: 40px;
    }
    .pptablemobile {
        display:none;
    }
}

@media (max-width: 991px) {

    .footer-col3 {
        text-align:center !important;
    }

    .pptable-desktop {
        display:none;
    }
    .contact-form-bottom .col-lg-4 + .col-lg-4 {
        margin-top: 40px;
    }
}



